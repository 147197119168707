import { User } from '@supabase/gotrue-js';
import { Database } from '../types/supabase';
import { createBrowserClient } from '@supabase/ssr';

export const supabase = createBrowserClient<Database>(
  process.env.NEXT_PUBLIC_SUPABASE_URL!,
  process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY!,
  {
    cookies: {},
    auth: {
      storageKey: process.env.NEXT_PUBLIC_COOKIE_NAME
    },
    cookieOptions: {
      name: process.env.NEXT_PUBLIC_COOKIE_NAME
    }
  }
);

export const getActiveProductsWithPrices = async () => {
  const { data, error } = await supabase
    .from('products')
    .select('*, prices(*)')
    .eq('active', true)
    .eq('metadata->>version', 'v2')
    .eq('prices.active', true)
    // .order('metadata->index')
    // .order('interval', { foreignTable: 'prices', ascending: true });
    .order('unit_amount', { foreignTable: 'prices', ascending: false });

  const sorted = data?.sort((a, b) => {
    return (a.prices[0].unit_amount ?? 0) - (b.prices[0].unit_amount ?? 0);
  });

  if (error) {
    console.log(error.message);
    throw error;
  }

  return (sorted as any) || [];
};

export const updateUserName = async (user: User, name: string) => {
  await supabase
    .from('users')
    .update({
      full_name: name
    })
    .eq('id', user.id);
};
