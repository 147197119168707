import { supabase } from '@/utils/supabase-client';
import { proxy, useSnapshot } from 'valtio';

const store = proxy({
  data: {
    sidebar_pinned: false,
    info_panel: {
      open: false,
      content_id: null as string | null,
      content_type: null as string | null
    },
    chat: {
      sidebar_open: false
    }
  },
  // Additional state can be defined here.
  async update(key, value) {
    // Update the state.
    this.data = { ...this.data, [key]: value };

    const { data, error } = await supabase.auth.getSession();

    if (error) {
      console.error('error', error);
      return;
    }
    const userId = data?.session?.user?.id;

    await supabase
      .from('users')
      .update({ ui_state: this?.data })
      .eq('id', userId as string)
      .single();
  }
});

export function useUiStore() {
  return useSnapshot(store?.data);
}

export default store;
