import { supabase } from '@/utils/supabase-client';
import { useUserAgent } from 'next-useragent';
// import { v4 } from 'uuid';

type TrackingEventOptions = {
  sync_google_analytics?: boolean;
};

export default function useTracking() {
  const uaString =
    typeof window !== 'undefined' ? window.navigator?.userAgent : '';
  const ua = useUserAgent(uaString);

  const trackEvent = async (
    type: 'event',
    name: string,
    data?: Record<string, any>,
    options: TrackingEventOptions = {
      sync_google_analytics: true
    }
  ) => {
    const { data: sessionObject, error } = await supabase.auth.getUser();

    let session_id = localStorage.getItem('session_id');

    if (typeof window === 'undefined') {
      return;
    }
    const path = decodeURIComponent(window?.location?.pathname);
    if (options?.sync_google_analytics && typeof gtag !== 'undefined') {
      gtag(type, name, data);
    }
    await supabase.from('msh_user_tracking_events').insert({
      user_id: sessionObject?.user?.id,
      type: type,
      name: name,
      meta: data,
      path: path,
      session_id: session_id,
      user_agent: uaString,
      is_mobile: ua.isMobile,
      is_bot: ua.isBot,
      screen_w: window.screen.width,
      screen_h: window.screen.height,
      browser: ua.browser,
      device_type: ua.deviceType,
      ua: JSON.stringify(ua),
      msh_id: data?.msh_id
    });
  };
  return trackEvent;
}
