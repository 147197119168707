import { TimeSeries } from '@/components/ui/Charts/SimpleMultiline';
import { MshEarningsRecord, MshVoteDirection } from '@/types/frontend/page';
import { proxy } from 'valtio';

export type CompanyResponse = {
  string: string;
  data: {
    msh_id: string;
    source_ref: string;
  };
};

export type HighlightToken = {
  word: string;
  msh_id?: string;
};
export type MshCluster = {
  cluster_uuid: string;
  slug: string;
  topic_class: string;
};

export type MessageContext = {
  last_message_id: string;
  session_id: string;
  req_start?: number;
  data_response: any;
  req_end?: number;
  tickers: CompanyResponse[];
  topics: any[];
  news_uuids: [];
  showEarnings?: boolean;
  showRevenue?: boolean;
  cmd: string;
  // events: MshUserEvent[];
  related_questions: { question: string; news_uuid: string }[];
  clusters: MshCluster[];
  series: TimeSeries[];
  startDate?: number;
  endDate?: number;
};

export type Message = {
  id: string;
  message: string;
  highlightedMessage?: HighlightToken[];
  is_response?: boolean;
  created_at?: string;
  user_id?: string;
  status?: string;
  vote?: MshVoteDirection;
  hidden_at?: string;

  context?: MessageContext;
};

export const chatState = proxy({
  exampleQuestions: [] as string[],
  currentContext: {} as MessageContext,
  sessionId: undefined as string | undefined,
  activeSession: undefined as any,
  sessions: [] as any[],
  messages: [] as any[],
  quotes: [] as any[],
  quote_idx: [] as string[],
  earnings: {} as Record<string, MshEarningsRecord[]>,
  currentId: 0,
  loading: false,
  prompt: '',
  remaining_credits: 10,
  currentIteration: 0,
  openRequest: 0,
  context_msh_id: null as string | null,
  context_cluster_uuid: undefined as string | undefined
});
