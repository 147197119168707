import { useEffect, useMemo, useRef, useState } from 'react';
import NProgress from 'nprogress'; //nprogress module

export interface LoadingState {
  isLoading: boolean;
  start: (timeout?: number) => void;
  done: () => void;
}

export const useLoading = (): LoadingState => {
  const [isLoading, setIsLoading] = useState(false);
  const startTimeout = useRef<ReturnType<typeof setTimeout>>();

  const resetTimeout = () => {
    if (startTimeout.current) {
      clearTimeout(startTimeout.current);
      startTimeout.current = undefined;
    }
  };

  useEffect(() => {
    return () => {
      resetTimeout();
      done();
    };
  }, [isLoading]);

  const start = (timeout = 0) => {
    setIsLoading(true);

    resetTimeout();
    const timer = setTimeout(() => NProgress.start(), timeout);
    startTimeout.current = timer;
  };

  const done = () => {
    setIsLoading(true);
    resetTimeout();
    NProgress.done();
  };

  const startRef = useRef(start);
  const doneRef = useRef(done);

  const loadingState = useMemo(() => {
    return {
      isLoading: isLoading,
      start: startRef.current,
      done: doneRef.current
    };
  }, [isLoading]);

  return loadingState;
};
