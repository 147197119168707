import { NavSection } from '@/components/Sidebar';

import { useUser } from '@/utils/useUser';
import { useRouter } from 'next/router';
import { navigationItems } from '@/components/data/navigation';

export function Navigation() {
  const { userDetails } = useUser();
  const router = useRouter();
  return (
    <div className="h-full  w-full">
      {Object.keys(navigationItems).map((ni) =>
        ni != 'dev' || userDetails?.show_dev_features
          ? NavSection(ni, navigationItems[ni], userDetails, router)
          : null
      )}
    </div>
  );
}
