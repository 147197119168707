import React, { Fragment } from 'react';

import {
  Column,
  ColumnFiltersState,
  ExpandedState,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getGroupedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  GroupingState,
  SortingFn,
  sortingFns,
  SortingState,
  Table,
  useReactTable
} from '@tanstack/react-table';

import {
  compareItems,
  RankingInfo,
  rankItem
} from '@tanstack/match-sorter-utils';

import classNames from 'classnames';
import SmallToggle from '../Controls/SmallToggle';
import { ArrowDown, ArrowRight, ArrowUp, Folder, X } from 'lucide-react';

declare module '@tanstack/table-core' {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

function Filter({
  column,
  table
}: {
  column: Column<any, unknown>;
  table: Table<any>;
}) {
  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id);

  const columnFilterValue = column.getFilterValue();

  const sortedUniqueValues = React.useMemo(
    () =>
      typeof firstValue === 'number'
        ? []
        : Array.from(column.getFacetedUniqueValues().keys()).sort(),
    [column.getFacetedUniqueValues()]
  );

  return typeof firstValue === 'number' ? (
    <div>
      <div className="flex space-x-2">
        <DebouncedInput
          type="number"
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
          value={(columnFilterValue as [number, number])?.[0] ?? ''}
          onChange={(value) =>
            column.setFilterValue((old: [number, number]) => [value, old?.[1]])
          }
          placeholder={`Min ${
            column.getFacetedMinMaxValues()?.[0]
              ? `(${column.getFacetedMinMaxValues()?.[0]})`
              : ''
          }`}
          className=" max-w-[5rem] border border-line rounded"
        />
        <DebouncedInput
          type="number"
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
          value={(columnFilterValue as [number, number])?.[1] ?? ''}
          onChange={(value) =>
            column.setFilterValue((old: [number, number]) => [old?.[0], value])
          }
          placeholder={`Max ${
            column.getFacetedMinMaxValues()?.[1]
              ? `(${column.getFacetedMinMaxValues()?.[1]})`
              : ''
          }`}
          className=" max-w-[5rem] border border-line rounded"
        />
      </div>
      <div className="h-1" />
    </div>
  ) : (
    <>
      <datalist id={column.id + 'list'}>
        {sortedUniqueValues.slice(0, 5000).map((value: any) => (
          <option value={value} key={value} />
        ))}
      </datalist>
      <DebouncedInput
        type="text"
        value={(columnFilterValue ?? '') as string}
        onChange={(value) => column.setFilterValue(value)}
        placeholder={`Search... (${column.getFacetedUniqueValues().size})`}
        className=" max-w-[8rem] border border-line rounded"
        list={column.id + 'list'}
      />
      <div className="h-1" />
    </>
  );
}

// A debounced input react component
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 100,

  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <input
      {...props}
      className={classNames(
        'text-xs font-normal no-padding bg-transparent border-line rounded focus:outline-blue-500  focus:ring-1 focus:ring-blue-500 focus:border-blue-500  focus:ring-inset outline-blue-500 focus:outline-blue-500 ring-offset-0 ',
        props.className
      )}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
}

export default function App({
  columns,
  data,
  sampleOnly = false,
  initialFilters = [],
  initalSorting = [],
  showPagination = true,
  initialPagination = {
    pageIndex: 0,
    pageSize: 40
  },
  initialVisibility = {},
  initialGrouping = []
}: any) {
  const rerender = React.useReducer(() => ({}), {})[1];
  const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value);
    // Store the itemRank info
    addMeta({
      itemRank
    });

    // Return if the item should be filtered in/out
    return itemRank.passed;
  };

  const fuzzySort: SortingFn<any> = (rowA, rowB, columnId) => {
    let dir = 0;

    // Only sort by rank if the column has ranking information
    if (rowA.columnFiltersMeta[columnId]) {
      dir = compareItems(
        rowA.columnFiltersMeta[columnId].itemRank!,
        rowB.columnFiltersMeta[columnId].itemRank!
      );
    }

    // Provide an alphanumeric fallback for when the item ranks are equal
    return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir;
  };

  const [columnFilters, setColumnFilters] =
    React.useState<ColumnFiltersState>(initialFilters);
  const [globalFilter, setGlobalFilter] = React.useState('');
  const [enableFilters, setEnableFilters] = React.useState(false);
  const [grouping, setGrouping] =
    React.useState<GroupingState>(initialGrouping);
  const [expanded, setExpanded] = React.useState<ExpandedState>(true);
  const [sorting, setSorting] = React.useState<SortingState>(initalSorting);

  const [pagination, setPagiantion] = React.useState(initialPagination);

  const [columnVisibility, setColumnVisibility] =
    React.useState(initialVisibility);

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter
    },
    state: {
      columnFilters,
      sorting,
      globalFilter,
      columnVisibility,
      pagination,
      grouping,
      expanded
    },
    onGroupingChange: setGrouping,
    onExpandedChange: setExpanded,
    onSortingChange: setSorting,
    onPaginationChange: setPagiantion,
    getExpandedRowModel: getExpandedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: false,
    debugHeaders: false,
    debugColumns: false
  });

  if (!table || !data || data.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className=" ">
      <div className="px-2">
        <div className="flex flex-row gap-x-3 mb-5 items-center justify-between">
          <div className="flex flex-row gap-x-5 items-center">
            <div>
              <DebouncedInput
                value={globalFilter ?? ''}
                onChange={(value) => setGlobalFilter(String(value))}
                className="p-2 font-sm border border-line rounded shadow-none "
                placeholder="Search all columns..."
              />
            </div>
            <div className="flex flex-row items-center text-xs">
              <SmallToggle
                label="Filters"
                onChange={(v) => setEnableFilters(v)}
                checked={enableFilters}
              />
            </div>
          </div>

          <div className="flex flex-row gap-x-2">
            <button
              className="rounded border-line border text-xs py-1 px-3 hover:bg-blue-600 transition-colors hover:text-white"
              onClick={() => setExpanded((old) => (old ? expanded : true))}
            >
              {expanded ? 'Collapse All' : 'Expand All'}
            </button>
            <button
              className="rounded border-line border text-xs py-1 px-3 hover:bg-blue-600 transition-colors hover:text-white"
              onClick={() => {
                setColumnVisibility(initialVisibility);
                setGrouping(initialGrouping);
                setSorting(initalSorting);
                setColumnFilters(initialFilters);
                setExpanded(expanded);
              }}
            >
              Reset
            </button>
          </div>
        </div>
      </div>
      <table className="min-w-full divide-y divide-line table-auto	">
        <thead className="py-5">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className="text-xs font-medium pb-1"
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        className={classNames(
                          'flex flex-col justify-between px-2',
                          {
                            'pb-1': enableFilters,
                            'pb-3': !enableFilters
                          }
                        )}
                      >
                        <div
                          {...{
                            className: classNames(
                              'flex flow-row justify-start items-center gap-x-2',
                              {
                                'cursor-pointer select-none':
                                  header.column.getCanSort()
                              }
                            ),
                            onClick: header.column.getToggleSortingHandler()
                          }}
                        >
                          {header.column.getCanGroup() ? (
                            // If the header can be grouped, let's add a toggle
                            <button
                              title={
                                header.column.getIsGrouped()
                                  ? 'Ungroup By'
                                  : 'Group By'
                              }
                              className="opacity-50 hover:opacity-100 text-sm"
                              {...{
                                onClick:
                                  header.column.getToggleGroupingHandler(),
                                style: {
                                  cursor: 'pointer'
                                }
                              }}
                            >
                              {header.column.getIsGrouped() ? (
                                <X />
                              ) : (
                                <Folder />
                              )}
                            </button>
                          ) : null}{' '}
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: <ArrowDown />,
                            desc: <ArrowUp />
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
          {enableFilters &&
            table.getHeaderGroups().map((headerGroup) => (
              <tr key={`${headerGroup.id}-ss`}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th className=" pb-3 " key={`hd-${header.id}`}>
                      {header.column.getCanFilter() ? (
                        <div className="   flex flex-row ">
                          <Filter column={header.column} table={table} />
                        </div>
                      ) : null}
                    </th>
                  );
                })}
              </tr>
            ))}
        </thead>
        <tbody className="divide-y divide-line">
          {table?.getRowModel()?.rows.map((row, rowIdx) => {
            return (
              <tr key={row.id || `row-m-${rowIdx}`}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td
                      key={cell.id}
                      {...{
                        className: classNames(' py-3 pl-2 text-xs ', {
                          '': cell.getIsGrouped(),
                          'blur-sm': sampleOnly && rowIdx > 5
                        })
                        // style: {
                        //   background: cell.getIsGrouped()
                        //     ? ''
                        //     : cell.getIsAggregated()
                        //     ? ''
                        //     : cell.getIsPlaceholder()
                        //     ? ''
                        //     : 'transparent'
                        // }
                      }}
                    >
                      {cell.getIsGrouped() ? (
                        // If it's a grouped cell, add an expander and row count
                        <div className="flex flex-row justify-start items-center gap-x-2 ">
                          <button
                            className="items-center flex flex-row gap-x-1"
                            {...{
                              onClick: row.getToggleExpandedHandler(),

                              style: {
                                cursor: row.getCanExpand()
                                  ? 'pointer'
                                  : 'normal'
                              }
                            }}
                          >
                            <span className={'opacity-50 hover:opacity-100'}>
                              {row.getIsExpanded() ? (
                                <ArrowDown />
                              ) : (
                                <ArrowRight />
                              )}{' '}
                            </span>
                            {/* <div className="  "> */}
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                            {/* </div> */}
                          </button>
                          {/* <div className="opacity-50">
                            ({row.subRows.length})
                          </div> */}
                        </div>
                      ) : cell.getIsAggregated() ? (
                        // If the cell is aggregated, use the Aggregated
                        // renderer for cell
                        flexRender(
                          cell.column.columnDef.aggregatedCell ??
                            cell.column.columnDef.cell,
                          cell.getContext()
                        )
                      ) : cell.getIsPlaceholder() ? (
                        cell.column.columnDef.meta?.['placeholder'] ? (
                          cell.column.columnDef.meta?.['placeholder']({ cell })
                        ) : null // For cells with repeated values, render null
                      ) : (
                        // Otherwise, just render the regular cell
                        <>
                          {typeof cell.getContext().getValue() == 'string' &&
                          (cell.getContext().getValue() as string)?.includes(
                            'http'
                          ) ? (
                            <a
                              href={
                                (cell.getContext().getValue() as string) || '#'
                              }
                              target="_blank"
                              className="hover:underline text-blue-500"
                            >
                              {(cell.getContext().getValue() as string) || '-'}
                            </a>
                          ) : (
                            flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )
                          )}
                        </>
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="h-2" />
      {showPagination && table.getState().pagination && (
        <div className="flex flex-row items-center justify-between px-5">
          <div className="">
            <span className="flex items-center gap-1 px-2 text-sm opacity-80">
              <div>Page</div>
              <strong>
                {table.getState().pagination?.pageIndex + 1} of{' '}
                {table.getPageCount()}
              </strong>
            </span>
          </div>
          <div className="flex items-center gap-2">
            <button
              className="btn-default secondary"
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
            >
              {'<<'}
            </button>
            <button
              className="btn-default secondary"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              {'<'}
            </button>
            <button
              className="btn-default secondary"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              {'>'}
            </button>
            <button
              className="btn-default secondary"
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            >
              {'>>'}
            </button>
          </div>

          <span className="flex items-center gap-1">
            Go to page:
            <input
              type="number"
              defaultValue={table.getState().pagination.pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                table.setPageIndex(page);
              }}
              className="ml-4 w-16 input-default"
            />
          </span>
          <select
            value={table.getState().pagination.pageSize}
            className="input-default-no-padding"
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50, 100, 200].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
          <div>{table.getRowModel().rows.length} Rows</div>
        </div>
      )}
    </div>
  );
}
