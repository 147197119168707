import { useEffect, useState } from 'react';

export default function useIsMobile() {
  const [width, setWidth] = useState<number>(
    typeof window !== 'undefined' ? window.innerWidth : 1024
  );

  function handleWindowSizeChange() {
    setWidth(window?.innerWidth);
  }
  useEffect(() => {
    if (typeof window === 'undefined') return;
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return width <= 768;
}
