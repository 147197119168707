import { scaleOrdinal } from '@visx/scale';
export const hashCode = (name) => {
  let hash = 0;
  for (let i = 0; i < name?.length; i++) {
    const character = name?.charCodeAt(i);
    hash = (hash << 5) - hash + character;
    hash = hash & hash; // Convert to 32bit integer
  }
  return Math.abs(hash);
};

export const chartColors = {
  light: {
    negative: '#dc2626',
    positive: '#16a34a',
    neutral: '#a8a29e'
  },
  dark: {
    negative: '#dc2626',
    positive: '#16a34a',
    neutral: '#f5f5f4'
  }
};

export const getModulus = (num, max) => {
  return num % max;
};

export const getDigit = (number, ntn) => {
  return Math.floor((number / Math.pow(10, ntn)) % 10);
};

export const getBoolean = (number, ntn) => {
  return !(getDigit(number, ntn) % 2);
};

export const getAngle = (x, y) => {
  return (Math.atan2(y, x) * 180) / Math.PI;
};

export const getUnit = (number, range, index) => {
  const value = number % range;

  if (index && getDigit(number, index) % 2 === 0) {
    return -value;
  } else return value;
};

export const getRandomColor = (number, colors, range) => {
  return colors[number % range];
};

export const ordinalColors = [
  '#6FCF97',
  '#ff7f0e',
  '#2ca02c',
  '#d62728',
  '#9467bd',
  '#8c564b',
  '#e377c2',
  '#7f7f7f',
  '#bcbd22',
  '#17becf'
];

export const getContrast = (hexcolor) => {
  // If a leading # is provided, remove it
  if (hexcolor.slice(0, 1) === '#') {
    hexcolor = hexcolor.slice(1);
  }

  // Convert to RGB value
  const r = parseInt(hexcolor.substr(0, 2), 16);
  const g = parseInt(hexcolor.substr(2, 2), 16);
  const b = parseInt(hexcolor.substr(4, 2), 16);

  // Get YIQ ratio
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;

  // Check contrast
  return yiq >= 128 ? '#000000' : '#FFFFFF';
};

export function hashColor(str: string) {
  const colors = [
    '#ffc62e',
    '#ff2fab',
    '#52f091',
    '#04a6ff',
    '#7324ff',
    '#C271B4',
    '#dc04ff',
    '#00ddc6',
    '#e4e3d8',
    '#eb4d70',
    '#f19938',
    '#6ce18b',
    '#78f6ef',
    '#9096f8',
    '#92A1C6',
    '#146A7C',
    '#F0AB3D',
    '#C20D90'
  ];
  const range = colors.length;
  const splitty = str?.split(':')?.length == 2 ? str.split(':')[1] : str;
  if (splitty?.length == 0) {
    return '#000000';
  }
  const color = getRandomColor(hashCode(splitty), colors, range);
  return color;
}

export const topicScale = () => {
  return scaleOrdinal({
    range: [
      '#ffc62e',
      '#7324ff',
      '#ff2fa0',
      '#52f091',
      '#04a600',
      '#dc0400',
      '#00ddc0',
      '#eb4d70',
      '#f19938',
      '#6ce18b',
      '#08aaef',
      '#9096f8'
    ]
  });
};

export function stringToColor(str) {
  if (!str) {
    return null;
  }
  const customColors = {
    Technology: 'hsl(120, 70%, 50%)', // Green
    Finance: 'hsl(300, 70%, 50%)', // Magenta
    Economy: 'hsl(210, 70%, 50%)', // Light Blue
    'International Relations': 'hsl(30, 70%, 50%)', // Orange
    Politics: 'hsl(270, 70%, 50%)', // Purple
    Sports: 'hsl(0, 70%, 50%)', // Red
    Cryptocurrency: 'hsl(60, 70%, 50%)', // Yellow
    Healthcare: 'hsl(90, 70%, 50%)' // Lime Green
  };

  if (customColors[str]) {
    return customColors[str];
  }

  // Hash function to convert string to integer
  function hashCode(s) {
    let hash = 0;
    for (let i = 0; i < s?.length; i++) {
      hash = (hash << 5) - hash + s.charCodeAt(i);
      hash |= 0; // Convert to 32-bit integer
    }
    return hash;
  }

  // Generate HSL color
  function intToHSLColor(hash) {
    const hue = Math.abs(hash) % 360;
    const saturation = 40 + (Math.abs(hash) % 30); // Range: [40, 70]
    const lightness = 45 + (Math.abs(hash) % 10); // Range: [45, 55]

    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  }

  // Convert input string to a deterministic color
  const hash = hashCode(str);
  const color = intToHSLColor(hash);
  return color;
}

export function tickerColor(ticker: string) {
  return stringToColor(ticker);
}
export function brighten(color: string, percent: number): string {
  // Remove the hash from the color value
  color = color.slice(1);

  // Convert the color to RGB
  let r = parseInt(color.slice(0, 2), 16);
  let g = parseInt(color.slice(2, 4), 16);
  let b = parseInt(color.slice(4, 6), 16);

  // Convert the color to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h,
    s,
    l = (max + min) / 2;

  if (max === min) {
    h = s = 0;
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  // Increase the lightness
  l = l + percent;
  if (l > 1) l = 1;

  // Convert the color back to RGB
  const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
  const p = 2 * l - q;
  r = hueToRgb(p, q, h + 1 / 3);
  g = hueToRgb(p, q, h);
  b = hueToRgb(p, q, h - 1 / 3);

  return `rgb(${Math.round(r * 255)}, ${Math.round(g * 255)}, ${Math.round(b * 255)})`;
}

function hueToRgb(p: number, q: number, t: number): number {
  if (t < 0) t += 1;
  if (t > 1) t -= 1;
  if (t < 1 / 6) return p + (q - p) * 6 * t;
  if (t < 1 / 2) return q;
  if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
  return p;
}
