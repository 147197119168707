import { TimeSeries } from '@/components/ui/Charts/SimpleMultiline';
import { MshPortfolio, RealtimeQuote } from '@/types/frontend/page';
import { Tables } from '@/types/supabase';
import { combineObjects } from '@/utils/array';
import { proxy } from 'valtio';

type HistoryQuote = {
  msh_id: string;
  adj_close: number;
  symbol?: string;
  date: string;
  close?: number;
};

export const DEMO_PORTFOLIOS = [
  '0721066b-e247-4fe5-aaf8-b3fe1efc04d0',
  '9a0fae96-133b-4714-a400-4423204f39ee',
  '89fff7b4-f693-4df1-a117-4add70a1740e'
];

function mergePortfolioData(arrays) {
  const portfolioMap = new Map();

  arrays.forEach((array) => {
    array?.forEach((obj) => {
      const {
        portfolio_id,
        date,
        asset_value,
        cash_balance,
        cash_transaction,
        portfolio_value,
        perc_change_without_cash,
        source
      } = obj;
      if (portfolioMap.has(date)) {
        const existingObj = portfolioMap.get(date);
        (existingObj.portfolio_id = 'all'),
          (existingObj.asset_value += asset_value || 0);
        existingObj.cash_balance += cash_balance || 0;
        existingObj.cash_transaction += cash_transaction || 0;
        existingObj.portfolio_value += portfolio_value || 0;
        existingObj.perc_change_without_cash += perc_change_without_cash || 0;
        existingObj.perc_change_without_cash_count += 1;
        existingObj.source = 'all';
      } else {
        portfolioMap.set(date, {
          portfolio_id: [portfolio_id],
          date: date,
          asset_value: asset_value || 0,
          cash_balance: cash_balance || 0,
          cash_transaction: cash_transaction || 0,
          portfolio_value: portfolio_value || 0,
          perc_change_without_cash: perc_change_without_cash || 0,
          perc_change_without_cash_count: 1,
          source: 'all'
        });
      }
    });
  });
  // Compute the average of perc_change_without_cash
  portfolioMap.forEach((obj) => {
    obj.perc_change_without_cash =
      obj.perc_change_without_cash / obj.perc_change_without_cash_count;
    delete obj.perc_change_without_cash_count; // remove the count field from the final result
  });

  const sortedArray = Array.from(portfolioMap.values()).sort(
    (a, b) => new Date(a.date).valueOf() - new Date(b.date).valueOf()
  );

  return sortedArray;
}

function combineHoldings(holdings) {
  if (!holdings) return [];
  // create an empty object to hold the combined holdings
  const combinedHoldings = {};

  // loop over the holdings array
  for (const holding of holdings) {
    // if we've seen this msh_id before
    if (combinedHoldings[holding.msh_id]) {
      // calculate the total quantities before adding the new quantity
      const oldTotalQuantity = combinedHoldings[holding.msh_id].quantity;

      // add the quantity to the existing quantity
      combinedHoldings[holding.msh_id].quantity += holding.quantity;

      // add the value_amount to the existing value_amount
      combinedHoldings[holding.msh_id].value_amount += holding.value_amount;

      // calculate the new weighted average price and cost_basis_amount, if they are not null
      if (holding.avg_price !== null) {
        combinedHoldings[holding.msh_id].avg_price =
          (oldTotalQuantity * combinedHoldings[holding.msh_id].avg_price +
            holding.quantity * holding.avg_price) /
          combinedHoldings[holding.msh_id].quantity;
      }

      if (holding.cost_basis_amount !== null) {
        combinedHoldings[holding.msh_id].cost_basis_amount =
          (oldTotalQuantity *
            combinedHoldings[holding.msh_id].cost_basis_amount +
            holding.quantity * holding.cost_basis_amount) /
          combinedHoldings[holding.msh_id].quantity;
      }
    } else {
      // if we haven't seen this msh_id before, copy the holding
      combinedHoldings[holding.msh_id] = { ...holding };
    }
  }

  // convert the combined holdings back into an array
  const combinedHoldingsArray = Object.values(combinedHoldings);

  return combinedHoldingsArray;
}

export const getSortedPortfolioKeys = (storeArgument) => {
  return Object.keys(storeArgument.portfolios)
    .filter((p) => !DEMO_PORTFOLIOS.includes(p))
    .sort((a, b) => {
      // 'all' should always come first
      if (a === 'all') return -1;
      if (b === 'all') return 1;

      // If is_paper is false, it should come after 'all' but before any true is_paper
      if (
        storeArgument.portfolios &&
        !storeArgument.portfolios[a]?.is_paper &&
        storeArgument.portfolios[b]?.is_paper
      )
        return -1;
      if (
        storeArgument.portfolios &&
        storeArgument.portfolios[a]?.is_paper &&
        !storeArgument.portfolios[b]?.is_paper
      )
        return 1;

      // At last sort remaining elements by their name
      if (storeArgument.portfolios[a]?.name < storeArgument.portfolios[b]?.name)
        return -1;
      if (storeArgument.portfolios[a]?.name > storeArgument.portfolios[b]?.name)
        return 1;

      return 0;
    });
};
export const setPortfolio = (storeArgument, portfolio) => {
  storeArgument.portfolios[portfolio.id] = {
    ...storeArgument.portfolios[portfolio.id],
    ...portfolio
  };
  const toCombine = Object.keys(storeArgument.portfolios)
    .filter((d) => d != 'all')
    .map((d) => storeArgument.portfolios[d]);

  const all = combineObjects(toCombine);
  const stats = {
    nlv: all.nlv,
    changeToday: 0,
    changeTodayPerc: 0
  };

  all.base_currency = 'USD';
  all.stats = stats;
  all.name = 'All Portfolios';
  all.id = 'all';
  all.user_id = toCombine[0].user_id;

  all.msh_holdings_with_quotes = combineHoldings(all.msh_holdings_with_quotes);
  all.is_virtual = true;
  all.portfolios_daily_history = mergePortfolioData(
    toCombine.map((d) => d.portfolios_daily_history)
  );
  storeArgument.portfolios['all'] = all;
};

export const setTransactions = (
  storeArgument,
  portfolio_id: string,
  transactions: any[]
) => {
  storeArgument.portfolios[portfolio_id] = {
    ...storeArgument.portfolios[portfolio_id],
    transactions: transactions
  };
};
export const deletePortfolio = (storeArgument, portfolioId: string) => {
  delete storeArgument.portfolios[portfolioId];
};

export const setHoldings = (
  storeArgument,
  portfolio_id: string,
  holdings: any[]
) => {
  storeArgument.portfolios[portfolio_id] = {
    ...storeArgument.portfolios[portfolio_id],
    msh_holdings_with_quotes: holdings
  };
};
export const updateStats = (storeArgument, portflioId: string, stats: any) => {
  if (storeArgument.portfolios[portflioId]) {
    storeArgument.portfolios[portflioId].stats = stats;
  }
};
export const updateDividendHistory = (
  storeArgument,
  portflioId: string,
  transactions: any[]
) => {
  storeArgument.portfolios[portflioId] = {
    ...storeArgument.portfolios[portflioId],
    dividendHistory: transactions
  };
};

export const createPortfolioStore = () =>
  proxy({
    portfolios: {},
    history: {},
    history_agg: {},
    quotes: {},
    connections: {}
  } as {
    portfolios: Record<string, MshPortfolio>;
    history: Record<string, HistoryQuote[]>;
    history_agg: Record<string, TimeSeries>;
    quotes: Record<string, RealtimeQuote>;
    connections: Record<string, Tables<'msh_portfolio_connections'>>;
  });
