import { createContext, useContext, useEffect, useMemo, useRef } from 'react';

import { AggregateTick } from '@/service/polygon/polygonTypes';
import { Socket } from 'socket.io-client';
interface SocketContextProps {
  initialSocket: Socket;
  children: React.ReactNode;
}
interface UseSocketParams {
  subscriptions: (string | null | undefined)[];
  cb: (msg: AggregateTick) => void;
}

interface SocketContextState {
  addSubscription?: (
    subscription: string,
    callback: React.RefObject<(msg: AggregateTick) => void>
  ) => void;
  removeSubscription?: (
    subscription: string,
    callback: React.RefObject<(msg: AggregateTick) => void>
  ) => void;
}

export const SocketContext = createContext<SocketContextState>({
  addSubscription: undefined,
  removeSubscription: undefined
});

export const SocketProvider = ({
  initialSocket,
  children
}: SocketContextProps) => {
  const socket = useRef(initialSocket);
  const subscriptions = useRef<string[]>([]);
  const callbacks = useRef<{
    [key: string]: React.RefObject<(msg: AggregateTick) => void>[];
  }>({});
  const handleMessage = (msg: AggregateTick) => {
    if (!msg || !msg.sym) {
      console.error('Invalid message', msg);
      return;
    }
    const topic = msg.sym;
    let wildcardTopic;

    if (msg.sym.includes('O:')) {
      wildcardTopic = `${msg.sym.substring(0, msg.sym.length - 15)}`;
    }
    if (msg.ev === 'A') {
      if (Object.keys(callbacks.current).includes(topic)) {
        callbacks.current[topic]?.forEach((cb) => {
          cb?.current?.(msg);
        });
      }
      if (
        wildcardTopic &&
        Object.keys(callbacks.current).includes(wildcardTopic)
      ) {
        callbacks.current[wildcardTopic]?.forEach((cb) => {
          cb.current?.(msg);
        });
      }
    }
  };

  useMemo(() => {
    socket?.current?.on('A', handleMessage);
  }, []);

  const addSubscription = (
    subscription: string,
    cb: React.RefObject<(msg: AggregateTick) => void>
  ) => {
    if (!subscriptions.current.includes(subscription)) {
      subscriptions.current.push(subscription);
      socket?.current?.emit('join', { room: subscription });
    } else {
      socket?.current?.emit('refresh', { room: subscription });
    }
    callbacks.current[subscription] = [
      cb,
      ...(callbacks.current[subscription] || [])
    ];
  };

  const removeSubscription = (
    subscription: string,
    cb: React.RefObject<(msg: AggregateTick) => void>
  ) => {
    const newCallback = callbacks.current[subscription]?.filter(
      (stored) => stored !== cb
    );
    if (newCallback?.length > 0) {
      callbacks.current[subscription] = newCallback;
    } else {
      delete callbacks.current[subscription];
      socket?.current?.emit('leave', { room: subscription });
      subscriptions.current = subscriptions.current?.filter(
        (stored) => stored !== subscription
      );
    }
  };

  const contextState = useMemo(() => {
    return {
      socket: socket,
      subscriptions: [],
      addSubscription: addSubscription,
      removeSubscription: removeSubscription
    };
  }, []);

  return (
    <SocketContext.Provider value={contextState}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = ({ subscriptions, cb }: UseSocketParams) => {
  const ctx = useContext(SocketContext);
  const { addSubscription, removeSubscription } = ctx || {};
  const cbRef = useRef(cb);
  cbRef.current = cb;
  const addSubscriptionRef = useRef(addSubscription);
  const removeSubscriptionRef = useRef(removeSubscription);
  const subscriptionsString = JSON.stringify(subscriptions);

  useEffect(() => {
    if (subscriptions) {
      for (const sub of subscriptions) {
        if (typeof sub == 'string') {
          addSubscriptionRef.current?.(sub, cbRef);
        }
      }
    }
    const removeSubSaved = removeSubscriptionRef.current;
    return () => {
      if (subscriptions) {
        for (const sub of subscriptions) {
          if (typeof sub == 'string') {
            removeSubSaved?.(sub, cbRef);
          }
        }
      }
    };
  }, [subscriptionsString]);
};
