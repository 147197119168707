import { useUiStore } from '@/store/ui';
import classNames from 'classnames';
import uiStore from '@/store/ui';
import { useEffect, useState } from 'react';
import { supabase } from '@/utils/supabase-client';
import { renderJsonObject } from '@/utils/json';
import { X } from 'lucide-react';
import LoadingDots from '../LoadingDots';

export default function InfoPanel() {
  const ui = useUiStore();
  const [loading, setLoading] = useState(false);
  const { content_type, content_id, open } = ui.info_panel;
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    if (!content_id || !content_type) return;
    if (ui.info_panel.open) {
      setLoading(true);
    }
    if (content_type == 'person') {
      supabase
        .from('msh_company_report_people')
        .select('profile')
        .eq('id', content_id)
        .single()
        .then((res) => {
          const dat = res.data;

          setData(res.data);
          setLoading(false);
        });
    }
  }, [open, content_id, content_type]);

  return (
    <div
      className={classNames(
        'h-full fixed right-0 top-0 pt-14 bg-white dark:bg-black transition-all border-l border-line shadow-lg z-30 overflow-y-auto',
        {
          'w-0': !open,
          'w-[32rem]': open
        }
      )}
    >
      <button
        className="p-2"
        onClick={() =>
          uiStore.update('info_panel', {
            open: false
            //   content_id: null,
            //   content_type: null
          })
        }
      >
        <X />
      </button>
      <div className="h-full">
        {loading ? (
          <div className="w-full h-full flex justify-center items-center ">
            <LoadingDots />
          </div>
        ) : (
          renderJsonObject(data)
        )}
      </div>
    </div>
  );
}
