import { supabase } from '@/utils/supabase-client';
import { useEffect } from 'react';
import { proxy, useSnapshot } from 'valtio';
import { useDebouncedCallback } from 'use-debounce';
import { Tables } from '@/types/supabase';

const tickerDataStore = proxy({
  tickerData: {}
});

const fetchTicker = async (symbols) => {
  const toFetch = symbols
    .filter((t) => t)
    .filter((t) => t !== 'loading')
    .filter((s) => !tickerDataStore.tickerData[s]);
  if (!toFetch || toFetch.length < 1) {
    return;
  }
  // console.log('Fetching tickers:', toFetch);
  return await supabase
    .from('msh_quotes_realtime')
    .select(
      'ticker,last_price,market,last_close,change_perc_today,abs_change_perc_today,abs_change_perc_today,name,symbol,currency_symbol,source_ref,logo,msh_id'
    )
    .in('msh_id', toFetch)
    .then((res) => {
      if (res.error) {
        console.error('Error fetching tickers:', res.error);
        return;
      } else {
        return res?.data?.reduce(
          (acc, obj) => {
            if (!obj || !obj?.msh_id) return acc;
            acc[obj.msh_id] = obj;
            return acc;
          },
          {} as Record<string, Partial<Tables<'msh_quotes_realtime'>>>
        );
      }
    });
};

const useTickerData = (symbols: string[]) => {
  const dataSnap = useSnapshot(tickerDataStore);

  const debouncedCallback = useDebouncedCallback(() => {
    const missingSymbols = symbols
      .filter((s) => s != '')
      .filter((s) => !dataSnap.tickerData[s]);
    if (!missingSymbols.length) return;

    const chunks = missingSymbols.reduce((acc, s, i) => {
      const idx = Math.floor(i / 10);
      if (!acc[idx]) {
        acc[idx] = [] as string[];
      }
      acc[idx].push(s);
      return acc;
    }, [] as string[][]);

    chunks.forEach((chunk) => {
      fetchTicker(chunk).then((data) => {
        tickerDataStore.tickerData = {
          ...tickerDataStore.tickerData,
          ...data
        };
      });
    });
  }, 250);

  useEffect(() => {
    debouncedCallback();
  }, [symbols, debouncedCallback]);

  return dataSnap;
};

export { useTickerData };
