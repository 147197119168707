export function shuffle<T>(array: T[]): T[] {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex]
    ];
  }

  return array;
}

export function combineObjects(arr) {
  return arr.reduce((acc, cur) => {
    Object.keys(cur).forEach((key) => {
      if (acc[key] === undefined) {
        acc[key] = cur[key];
      } else if (typeof acc[key] === 'number' && typeof cur[key] === 'number') {
        acc[key] += cur[key];
      } else if (typeof acc[key] === 'string' && typeof cur[key] === 'string') {
        acc[key] += cur[key];
      } else if (Array.isArray(acc[key]) && Array.isArray(cur[key])) {
        acc[key] = [...acc[key], ...cur[key]];
      }
    });
    return acc;
  }, {});
}
