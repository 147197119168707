import { useUser } from '@/utils/useUser';
import getConfig from 'next/config';
import { useUserAgent } from 'next-useragent';

import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import useTracking from './useTracking';
const { publicRuntimeConfig } = getConfig();

export default function usePageView() {
  const router = useRouter();
  const userObject = useUser();
  const [renderClient, setRenderClient] = useState<boolean>();
  const renderRef = useRef(renderClient);
  renderRef.current = renderClient;

  const uaString =
    typeof window !== 'undefined' ? window.navigator?.userAgent : '';
  const ua = useUserAgent(uaString);

  const userRef = useRef(userObject);
  userRef.current = userObject;

  useEffect(() => {
    setRenderClient(true);
  }, []);

  const trackEvent = useTracking();
  const trackPage = (url) => {
    trackEvent(
      'event',
      'page_view',
      {
        path: url
      },
      { sync_google_analytics: false }
    );
  };

  useEffect(() => {
    trackPage(router.asPath);
  }, []);

  const handleRouteChange = (url) => {
    trackPage(decodeURIComponent(url));
  };
  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);
}
