import { NavigationItem, navigationItems } from '@/components/data/navigation';
import { supabase } from '@/utils/supabase-client';
import { useUser } from '@/utils/useUser';
import cn from 'classnames';

import { Dialog, Transition } from '@headlessui/react';

import { Folder, Pin, X } from 'lucide-react';

import useIsMobile from '@/hooks/useIsMobile';
import { TickDirection } from '@/service/polygon/polygonTypes';
import { useLists } from '@/store/list';
import { RealtimeQuote } from '@/types/frontend/page';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Fragment, useEffect, useRef, useState } from 'react';

import uiStore, { useUiStore } from '@/store/ui';
import { animated, useTransition } from 'react-spring';

const ListItem = ({ report, style }) => {
  const router = useRouter();
  const activePath = router?.asPath;
  const path = router?.pathname;
  const target = path.includes('lists/[id]/')
    ? path.replaceAll('[id]', report?.id)
    : '/lists/' + report?.id;

  const active = activePath.includes(report?.id);

  return (
    <animated.div
      style={style}
      className="overflow-clip hover:bg-neutral-100 hover:dark:bg-neutral-900"
    >
      <Link
        href={target}
        className={cn(
          'flex w-full justify-between px-3 text-left text-sm focus:outline-none focus-visible:ring focus-visible:ring-blue-500/75 py-2',
          {
            'border-r-2 border-blue-500 dark:border-blue-600 text-primary dark:text-primary bg-zinc-100 dark:bg-slate-900':
              active,
            'hover:text-primary hover:bg-zinc-200/30 hover:dark:bg-slate-600/10 text-neutral-700 dark:text-neutral-300 hover:dark:text-primary':
              !active
          }
        )}
      >
        <div className="flex flex-row gap-x-4">
          <Folder className="mr-0 text-lg rounded p-1" />
          <div className="grow">
            <h2 className="whitespace-nowrap">{report.name}</h2>
            <div className="text-xs opacity-60 overflow-hidden overflow-ellipsis whitespace-nowrap max-w-[40ch]">
              {report.msh_flex_trigger_invocations?.[0]?.payload
                ?.inference_result?.explanation || ''}
            </div>
          </div>
        </div>
      </Link>
    </animated.div>
  );
};

export const NavSection = (
  header: string,
  items: NavigationItem[],
  userDetails,
  router
) => {
  return (
    <div key={`sb-nav-${header}`} className="flex flex-col  mb-5  ">
      <nav className="px-0 pb-0">
        {items
          .filter(
            (ul) =>
              (!ul.dev || userDetails?.show_dev_features) &&
              (!ul?.user || userDetails)
          )

          .map((item) => (
            <Link
              legacyBehavior
              key={`${item.name}`}
              href={
                item.userHref && userDetails?.id ? item.userHref : item.href
              }
              target={item.href.includes('http') ? '_blank' : ''}
            >
              <a
                className={cn(
                  router?.pathname == item.href ||
                    router?.pathname == item.userHref ||
                    item?.paths?.reduce(
                      (a, c) => a || c.test(router?.pathname),
                      false
                    )
                    ? '  border-r-2 border-blue-500 dark:border-blue-600 text-primary dark:text-primary bg-zinc-100 dark:bg-slate-900'
                    : ' hover:text-primary hover:bg-zinc-200/30  hover:dark:bg-slate-600/10 text-neutral-700 dark:text-neutral-300 hover:dark:text-primary ',
                  'group flex items-center px-3 py-2 text-sm   tracking-normal'
                )}
              >
                <span className="relative">
                  <item.icon
                    className={cn(`mr-0 text-lg rounded p-1 `, {
                      // '': !item.highlight && router?.pathname == item.href,
                      // '': !item.highlight && router?.pathname != item.href,
                      // '': item.highlight && router?.pathname == item.href,
                      // '': item.highlight && router?.pathname != item.href
                    })}
                  />
                  {/* {item.name == 'Watchlist' && watchlistBadge > 0 ? (
                    <span className="bg-blue-400 dark:bg-blue-500 rounded-full w-2 h-2  -top-1 -right-1 text-xs  absolute"></span>
                  ) : null} */}

                  {item.name == 'supergreen' ? (
                    <span className=" animate-pulse bg-green-400 dark:bg-green-500 rounded-full w-2 h-2  -top-1 -right-1 text-xs  absolute"></span>
                  ) : null}
                </span>

                <span className="ml-5 whitespace-pre	">{item.name}</span>
              </a>
            </Link>
          ))}
      </nav>
    </div>
  );
};

const Sidebar = () => {
  const [portfolioTickerData, setPortfolioTickerData] = useState<
    RealtimeQuote[]
  >([]);
  const [activePortfolioTickerData, setActivePortfolioTickerData] = useState<
    RealtimeQuote[]
  >([]);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const router = useRouter();
  const { isLoading, userDetails, watchlist } = useUser();

  const [position, setPosition] = useState<number>();
  const positionRef = useRef(position);
  positionRef.current = position;

  const [sidebarExpanded, setSidebarExpanded] = useState(false);
  // const [sidebarAlwaysExpanded, setSidebarAlwaysExpanded] = useState(false);
  const listsSnapshot = useLists();

  const transition = useTransition(listsSnapshot.items.slice(0, 8), {
    from: { opacity: 0, transform: 'translateY(-20px)' },
    enter: { opacity: 1, transform: 'translateY(0)' },
    leave: { opacity: 0, transform: 'translateY(20px)' },
    key: (item) => item.id
  });

  useEffect(() => {
    const activeTicker = router?.query.ticker as string;

    supabase
      .from('msh_quotes_realtime')
      .select('*')
      .eq('msh_id', activeTicker)
      .limit(1)
      .then((res) => {
        const percy = res.data?.map((d) => {
          return {
            ...d,
            tick_direction:
              d.change_perc_today && d.change_perc_today > 0
                ? TickDirection.Up
                : TickDirection.Down
          };
        });

        setActivePortfolioTickerData(percy as RealtimeQuote[]);
      });
  }, [router?.query]);

  const ui = useUiStore();
  const isMobile = useIsMobile();
  const sidebarActuallyOpen =
    sidebarExpanded || (ui.sidebar_pinned && !isMobile);

  // const [lists, setLists] = useState<any[]>([]);

  const closeSidebar = () => {
    setSidebarOpen(false);
  };

  useEffect(() => {
    router.events.on('routeChangeStart', closeSidebar);
    return () => {
      router.events.off('routeChangeStart', closeSidebar);
    };
  }, []);

  const overlay = () => (
    <Transition.Root show={sidebarOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 flex z-50 lg:hidden"
        onClose={setSidebarOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-100"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-100 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-100 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className="relative flex-1 flex flex-col max-w-xs w-full pt-20 pb-4 bg-white/80 dark:bg-black/80 backdrop-blur-xl">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-100"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="absolute top-12 right-0 -mr-0 pt-2">
                <button
                  type="button"
                  className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none "
                  onClick={() => setSidebarOpen(false)}
                >
                  <span className="sr-only">Close sidebar</span>
                  <X
                    className="h-5 w-5 text-white opacity-80"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 flex items-center px-4"></div>
            <div className="mt-5 flex-1 h-0 overflow-y-auto">
              {Object.keys(navigationItems).map((ni) =>
                (ni != 'dev' || userDetails?.show_dev_features) &&
                (ni != 'beta' || userDetails?.show_beta_features)
                  ? NavSection(ni, navigationItems[ni], userDetails, router)
                  : null
              )}
            </div>
          </div>
        </Transition.Child>
        <div className={cn('flex-shrink-0')} aria-hidden="true">
          {/* Dummy element to force sidebar to shrink to fit close icon */}
        </div>
      </Dialog>
    </Transition.Root>
  );

  return (
    <Fragment>
      {overlay()}

      <div
        className={cn(
          'hidden lg:flex lg:flex-col lg:fixed lg:inset-y-0 z-30 dark:bg-black/60 dark:backdrop-blur-xl  bg-main-background transition-width	 duration-50 ease-in-out   ',
          {
            'shadow-slate-100 dark:shadow-black/30 shadow-xl':
              sidebarActuallyOpen && !ui.sidebar_pinned,
            'lg:w-52 ': sidebarActuallyOpen,
            'lg:w-12 ': !sidebarActuallyOpen
          }
        )}
        onMouseLeave={() => setSidebarExpanded(false)}
        onMouseEnter={() => setSidebarExpanded(true)}
      >
        <div className="flex flex-col flex-grow   pt-20 border-r   border-line overflow-y-auto scrollbar-none divide-zinc-300 dark:divide-zinc-800">
          {Object.keys(navigationItems).map((ni) =>
            (ni != 'dev' || userDetails?.show_dev_features) &&
            (ni != 'beta' || userDetails?.show_beta_features)
              ? NavSection(ni, navigationItems[ni], userDetails, router)
              : null
          )}

          <div key={`sb-na`} className="flex flex-col  mb-5  ">
            <div className="sidebar">
              {transition((style, item) => (
                <ListItem report={item} style={style} key={item.id} />
              ))}
            </div>
            {/* {listsSnapshot.items.slice(0, 10).map((l) => (
              <ListItem report={l} key={l?.id} />
            ))} */}
          </div>
        </div>
        <div>
          <button
            className={cn('border-red-500 absolute bottom-5 left-2', {
              hidden: !sidebarActuallyOpen,
              'text-blue-500': ui.sidebar_pinned,
              'text-primary opacity-20': !ui.sidebar_pinned
            })}
            onClick={() => uiStore.update('sidebar_pinned', !ui.sidebar_pinned)}
          >
            <Pin strokeWidth={2} />
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export default Sidebar;
