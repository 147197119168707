import { Transition } from '@headlessui/react';
import { AlertTriangle, CheckCircle2, X } from 'lucide-react';
import Link from 'next/link';
import {
  createContext,
  Fragment,
  useContext,
  useMemo,
  useRef,
  useState
} from 'react';

export type MessageType = 'ok' | 'error';
interface NotificationItem {
  type?: MessageType;
  title?: string;
  message: string;
  href?: string;
}
export interface NotificationState {
  showNotification: (notification: NotificationItem) => void;
  showError: (notification: NotificationItem) => void;
  error: (notification: string) => void;
  success: (notification: string) => void;
  component?: React.ReactElement;
}

export const NotificationContext = createContext<NotificationState>({
  showNotification: () => {
    console.log('try to show notification');
  },
  success: () => {
    console.log('try to show notification');
  },
  error: () => {
    console.log('try to show notification');
  },
  showError: () => {
    console.log('trying to  show error');
  },
  component: undefined
});

export const NotificationProvider = (props: any) => {
  const startTimeout = useRef<ReturnType<typeof setTimeout>>();

  const [show, setShow] = useState(false);
  const [item, setItem] = useState<NotificationItem | null>(null);
  const [type, setType] = useState<MessageType>('ok');

  const showNotification = (item: NotificationItem) => {
    resetTimeout();
    setItem(item);
    setShow(true);
    setType('ok');
    startTimeout.current = setTimeout(() => setShow(false), 5000);
  };
  const showError = (item: NotificationItem) => {
    resetTimeout();
    setItem(item);
    setType('error');
    setShow(true);
    startTimeout.current = setTimeout(() => setShow(false), 20000);
  };

  // const notificationRef = useRef(showNotification);
  // notificationRef.current = showNotification;
  const resetTimeout = () => {
    if (startTimeout.current) {
      clearTimeout(startTimeout.current);
      startTimeout.current = undefined;
    }
  };
  const component = useMemo(() => {
    if (!show || !item) {
      return <></>;
    }

    return (
      <>
        {/* Global notification live region, render this permanently at the end of the document */}
        <div
          aria-live="assertive"
          className="fixed inset-0 flex items-start px-4 py-5 pointer-events-none sm:p-6  z-60 top-14"
        >
          <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
            {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
            <Transition
              show={show}
              as={Fragment}
              enter="transform ease-out duration-300 transition"
              enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
              enterTo="translate-y-0 opacity-100 sm:translate-x-0"
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="max-w-sm w-full dark:bg-neutral-900 border-line border bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden shadow-black">
                <div className="p-4">
                  <div className="flex items-start">
                    <div className="flex-shrink-0">
                      {type === 'ok' ? (
                        <CheckCircle2
                          className="h-6 w-6 text-green-400"
                          aria-hidden="true"
                        />
                      ) : (
                        <AlertTriangle
                          className="h-6 w-6 text-red-500"
                          aria-hidden="true"
                        />
                      )}
                    </div>
                    <div className="ml-3 w-0 flex-1 pt-0.5">
                      <p className="text-sm font-medium text-gray-900 dark:text-neutral-200">
                        {/* {item.title || type == 'ok' ? 'Success' : 'Error'} */}
                        {item.title}
                      </p>
                      <p className="mt-1 mb-2 text-sm text-gray-300">
                        {item.message}
                      </p>
                      {item.href ? (
                        <Link legacyBehavior href={item.href} passHref={false}>
                          <a className="text-blue-600 text-sm underline">
                            Show More
                          </a>
                        </Link>
                      ) : null}
                    </div>
                    <div className="ml-4 flex-shrink-0 flex">
                      <button
                        className="rounded-md inline-flex text-gray-400 dark:text-red-200 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        onClick={() => {
                          setShow(false);
                        }}
                      >
                        <span className="sr-only">Close</span>
                        <X
                          className="h-5 w-5 bg-transparent"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Transition>
          </div>
        </div>
      </>
    );
  }, [show]);

  const success = (notification: string) => {
    showNotification({ title: 'success', message: notification });
  };
  const error = (notification: string) => {
    showError({ title: 'error', message: notification });
  };

  // const notificationState = useMemo(() => {
  //   return {
  //     showNotification: notificationRef.current,
  //     component: component
  //   };
  // }, [component]);

  return (
    <NotificationContext.Provider
      value={{
        showNotification: showNotification,
        showError: showError,
        success,
        error,
        component
      }}
      {...props}
    />
  );
};

export const useNotification = () => useContext(NotificationContext);
