import { LoadingState } from '@/hooks/useLoading';
import { createContext, useContext, useState } from 'react';
import { useLoading } from '../hooks';
export interface AppState {
  searchOpen?: boolean;
  setSearchOpen?: (searchOpen: boolean) => void;
  count?: number;
  loading?: LoadingState;
  setCount?: (count: number) => void;
  children?: React.ReactNode;
  isAppEnvironment?: boolean;
}
export const AppStateContext = createContext<AppState>({
  searchOpen: false,
  setSearchOpen: () => {},
  children: null,
  count: 0,
  setCount: () => {},
  isAppEnvironment: false
});
// todo: right now this whole thing is not used
export const AppStateContextProvider = (props: AppState) => {
  const loading = useLoading();
  const initState = {
    searchOpen: false,
    setSearchOpen: (searchOpen: boolean) =>
      setState({ ...state, searchOpen: searchOpen }),
    count: 0,
    setCount: (count: number) => setState({ ...state, count: count }),
    isAppEnvironment: props.isAppEnvironment || false
  };

  const [state, setState] = useState(initState);

  return (
    <AppStateContext.Provider value={state}>
      {props.children}
    </AppStateContext.Provider>
  );
};

export const useAppState = () => useContext(AppStateContext);
