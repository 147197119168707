import { useEffect, useRef } from 'react';

import { useNotification } from './useNotification';
import { useUser } from '@/utils/useUser';
import { supabase } from '@/utils/supabase-client';
import { RealtimeChannel } from '@supabase/supabase-js';
// import { useSupabaseClient } from '@supabase/auth-helpers-react';

export default function useTradeNotifications() {
  const notification = useNotification();
  const nofiticationRef = useRef(notification);
  nofiticationRef.current = notification;

  const { user } = useUser();

  useEffect(() => {
    if (!user?.id) {
      return;
    }
    const subscription = supabase
      .channel(`realtime:public:msh_portfolio_transactions:${user?.id}`)
      .on(
        'postgres_changes',
        { event: '*', schema: 'public', table: 'msh_portfolio_transactions' },
        (payload) => {
          if (!payload.new) {
            return;
          }
          nofiticationRef.current.showNotification({
            title: 'Transaction Completed',
            message: `${
              payload.new['quantity'] > 0 ? 'Buy' : 'Sell'
            } Order for ${payload.new['quantity']} ${payload.new['msh_id']}  `,
            href: '/portfolios'
          });
        }
      )

      .subscribe();
    return () => {
      supabase.removeChannel(subscription);
    };
  }, [notification, user]);

  useEffect(() => {
    if (!user?.id) {
      return;
    }
    const subscriptionTwo = supabase
      .channel(`realtime:public:msh_portfolio_orders:${user?.id}`)
      .on(
        'postgres_changes',
        { event: '*', schema: 'public', table: 'msh_portfolio_orders' },
        (payload) => {
          if (!payload.new) {
            return;
          }
          if (payload.new['status'] === 'executed') {
            return;
          }
          nofiticationRef.current.showNotification({
            title: 'Order Updated',
            message: `${
              payload.new['quantity'] > 0 ? 'Buy' : 'Sell'
            } Order for ${payload.new['quantity']} ${
              payload.new['msh_id']
            } now in status: ${payload.new['status']} `
          });
        }
      )
      .subscribe();
    return () => {
      supabase.removeChannel(subscriptionTwo);
    };
  }, [notification]);
}
