import { format } from 'd3-format';

export function parseFloatSafe(value) {
  const result = Number.parseFloat(value);

  if (Number.isNaN(result)) {
    return null; // or throw an error, or return a default value, etc.
  }

  return result;
}

export function parseIntSafe(value) {
  const result = Number.parseInt(value);

  if (Number.isNaN(result)) {
    return null; // or throw an error, or return a default value, etc.
  }

  return result;
}

export function isNumber(value) {
  const result = Number.parseFloat(value);

  if (Number.isNaN(result)) {
    return false;
  }

  return true;
}

const siFormat = format('.4~s');
export const formatNumberWithSuffix = (number?: number | string) => {
  const parsed = parseFloatSafe(number);

  if (parsed === null) {
    return null;
  }

  const ABS_TRILLION = 1000000000000;
  const ABS_BILLION = 1000000000;
  const ABS_MILLION = 1000000;
  const ABS_THOUSAND = 1000;

  let formattedNumber;

  const formatSuffix = (value: number, suffix: string) => {
    // If the number is whole, no need to use decimal places.
    if (value % 1 === 0) {
      return value.toFixed(0) + suffix;
    } else {
      return value.toFixed(2) + suffix;
    }
  };

  if (Math.abs(parsed) >= ABS_TRILLION) {
    formattedNumber = formatSuffix(parsed / ABS_TRILLION, 'T');
  } else if (Math.abs(parsed) >= ABS_BILLION) {
    formattedNumber = formatSuffix(parsed / ABS_BILLION, 'B');
  } else if (Math.abs(parsed) >= ABS_MILLION) {
    formattedNumber = formatSuffix(parsed / ABS_MILLION, 'M');
  } else if (Math.abs(parsed) >= ABS_THOUSAND) {
    formattedNumber = formatSuffix(parsed / ABS_THOUSAND, 'K');
  } else {
    // If the number is whole, no need to use decimal places.
    formattedNumber = parsed % 1 === 0 ? parsed.toFixed(0) : parsed.toFixed(2);
  }

  return formattedNumber;
};
const roundTwoDecimals = (x: number) => {
  return x.toFixed(2);
};
export const formatPrice = (x: number | null | undefined, dec = 2) => {
  if (x === null || x === undefined) {
    return null;
  }
  if (x > 1e5) {
    return formatNumberWithSuffix(x);
  }
  return x && format(`,.${dec}f`)(x);
};
export const formatPriceNoDecimals = (x: number) => format(',.0f')(x);

export const formatTwoDecimals = (x: number) => format(',.2f')(x);
export const formatMillions = (x: number) => format(',.3f')(x * 1e-6);

export const formatPercent = (x: number, signed = false) => {
  if (isNaN(x)) {
    return '';
  }

  const perc = x; // x >= 1.0 ? x / 100 : x;

  // Check if the number is a whole number
  const isWholeNumber = (perc * 100) % 1 === 0;

  // Use different format for whole numbers
  const formatString = isWholeNumber
    ? `${signed ? '+' : ''},.0%`
    : `${signed ? '+' : ''},.2%`;

  return format(formatString)(perc);
};

export const formatAbsoluteChange = (x: number) => {
  if (Math.abs(x) > 1e5) {
    return formatNumberWithSuffix(x);
  }

  return format(',.2f')(x);
};

export const CurrencySymbols = {
  USD: '$', // US Dollar
  EUR: '€', // Euro
  CRC: '₡', // Costa Rican Colón
  GBP: '£', // British Pound Sterling
  GBp: '£', // British Pound Sterling
  ILS: '₪', // Israeli New Sheqel
  INR: '₹', // Indian Rupee
  JPY: '¥', // Japanese Yen
  KRW: '₩', // South Korean Won
  NGN: '₦', // Nigerian Naira
  PHP: '₱', // Philippine Peso
  PLN: 'zł', // Polish Zloty
  PYG: '₲', // Paraguayan Guarani
  THB: '฿', // Thai Baht
  UAH: '₴', // Ukrainian Hryvnia
  VND: '₫', // Vietnamese Dong
  AUD: 'A$', // Australian Dollar
  CAD: 'C$' // Canadian Dollar
} as { [key: string]: string };

export function defaultFormatter(v, l) {
  if (
    l.includes('date') ||
    l.includes('created') ||
    l.includes('updated') ||
    l.includes('time') ||
    l.includes('published')
  ) {
    return new Date(v).toLocaleDateString();
  }

  if (v == null) return null;

  const val = parseFloatSafe(v);
  if (
    (l.includes('growth') || l.includes('yiel') || l.includes('percent')) &&
    typeof val === 'number'
  ) {
    return formatPercent(val);
  }

  if (!val && typeof v === 'string') {
    return v;
  }
  if (!val && typeof v === 'object') {
    return JSON.stringify(v);
  }
  if (val == null) {
    return null;
  }
  // check if its a year
  if (val > 1900 && val < 2100) {
    return val;
  }

  // if (Math.abs(val) < 1) return formatPercent(val);
  return formatNumberWithSuffix(val);
}
