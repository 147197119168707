import { useState } from 'react';
import cn from 'classnames';

interface SmallToggleProps {
  label?: string;
  onChange?: (value: boolean) => void;
  disabled?: boolean;
  checked?: boolean;
}
export default function SmallToggle({
  label,
  onChange,
  checked,
  disabled = false
}: SmallToggleProps) {
  const [value, setValue] = useState(checked || false);
  return (
    <label
      // htmlFor="small-toggle"
      className={cn(
        'inline-flex relative items-center  gap-x-0.5 font-small   	',
        {
          'cursor-pointer': !disabled,
          'cursor-not-allowd grayscale pointer-events-none ': disabled
        }
      )}
    >
      <input
        type="checkbox"
        // value={checked ? 'checked' : 'unchecked'}
        checked={value}
        onChange={(e) => {
          setValue(e.target.checked);
          if (onChange) {
            onChange(e.target.checked);
          }
        }}
        // id="small-toggle"
        className="sr-only peer"
      />
      <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-500"></div>
      <span className="ml-1 text-xs  text-gray-900 dark:text-gray-300 ">
        {label}
      </span>
    </label>
  );
}
