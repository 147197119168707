// store.js
import { Tables } from '@/types/supabase';
import { supabase } from '@/utils/supabase-client';
import { proxy, useSnapshot } from 'valtio';

const store = proxy({
  loading: true, // Initial loading state
  items: [] as Tables<'msh_lists_with_edit_permission'>[], // Initial items, possibly empty
  addTicker: async (id, ticker) => {
    const { data, error } = await supabase
      .from('msh_lists_symbols')
      .insert({ msh_id: ticker, msh_symbol_list_id: id as string })
      .select('*,msh_quotes_realtime(source_ref)');

    if (error) {
      console.error('error', error);
      return;
    }
    const item = data?.[0];

    if (item) {
      store.getItemById(id)?.msh_lists_symbols_stock_id.push(item);
    }
  },

  removeTicker: async (id, ticker) => {
    supabase
      .from('msh_lists_symbols')
      .delete()
      .eq('msh_id', ticker)
      .then((res) => {
        if (!res.error) {
          console.log('removed symbol');
        }
        store.getItemById(id).msh_lists_symbols_stock_id = store
          .getItemById(id)
          .msh_lists_symbols_stock_id.filter(
            (stock) => stock.msh_id !== ticker
          );
      });
  },

  loadInitial: async () => {
    const { error, data } = await supabase
      .from('msh_lists_with_edit_permission')
      .select('*,msh_lists_symbols_stock_id(msh_id,source_ref)')
      .is('deleted_at', null)
      .order('created_at', { ascending: false });

    if (error) {
      console.error('error', error);
    } else {
      store.setItems(data);
    }
    store.loading = false;
  },

  getItemById(id) {
    return this.items.find((item) => item.id === id);
  },

  delete: async (id) => {
    const { error, data } = await supabase
      .from('msh_lists')
      .update({ deleted_at: new Date().toISOString() })
      .eq('id', id);
    if (error) {
      console.error('error', error);
      return;
    }
    store.items = store.items.filter((item) => item.id !== id);
  },
  update: async (id, updatedData, persist = false) => {
    if (persist) {
      const { error, data } = await supabase
        .from('msh_lists')
        .update(updatedData)
        .eq('id', id);
      if (error) {
        console.error('error', error);
        return;
      }
    }
    store.items = store.items.map((item) => {
      if (item.id === id) {
        return { ...item, ...updatedData };
      }
      return item;
    });
  },

  setItems(items) {
    this.items = items;
  },
  addItem(item) {
    this.items.push(item);
  }
  // removeItem(index) {
  //   this.items.splice(index, 1);
  // }
});

export function useLists() {
  return useSnapshot(store);
}

export default store;
