import { useEffect, useState } from 'react';
import cookie from 'cookie';

export default function useApp() {
  const [isApp, setIsApp] = useState(() => {
    if (typeof document !== 'undefined') {
      return cookie.parse(document.cookie)?.['is-app-environment'] === 'true';
    }
    return false;
  });

  useEffect(() => {
    if (typeof document !== 'undefined') {
      const value =
        cookie.parse(document.cookie)?.['is-app-environment'] === 'true';
      setIsApp(value);
    }
  }, []);

  return isApp;
}
