import { useSocket } from '@/hooks/useSocket';
import { AggregateTick } from '@/service/polygon/polygonTypes';
import { supabase } from '@/utils/supabase-client';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useRef, useState } from 'react';

export interface ViewLastTrade {
  lastUpdatedPrice: number;
  todayPercent: number;
  lastUpdated: Dayjs;
}
export const useLastTrade = (ticker: string) => {
  const [lastTrade, setLastTrade] = useState<ViewLastTrade | null>(null);
  const [lastClose, setLastClose] = useState<number>(0);

  const lastCloseRef = useRef<number>();
  lastCloseRef.current = lastClose;
  useEffect(() => {
    supabase
      .from('msh_quotes_realtime')
      .select(
        'ticker, name, last_price, last_close,  updated_at, change_perc_today, last_close_updated_at',
        {
          count: 'exact'
        }
      )
      .eq('ticker', ticker)
      .then((res) => {
        if (res.data && res.data.length == 1) {
          const val = res.data[0];
          if (!val.last_close || !val.last_price) {
            return;
          }

          const todayPercent =
            (val.last_price - val.last_close) / val.last_close;
          setLastClose(val.last_close);
          setLastTrade({
            lastUpdatedPrice: val.last_price,
            todayPercent: todayPercent,
            lastUpdated: dayjs()
          });
        }
      });
  }, [ticker]);

  useSocket({
    subscriptions: [ticker as string],
    cb: (data: AggregateTick) => {
      const updated = dayjs(data.e * 1e-6);
      if (!lastCloseRef.current) {
        return;
      }
      setLastTrade({
        lastUpdatedPrice: data.c,
        todayPercent: (data.c - lastCloseRef.current) / lastCloseRef.current,
        lastUpdated: updated
      });
    }
  });

  return lastTrade;
};
