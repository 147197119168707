import { RealtimeQuote } from '@/types/frontend/page';
import Link from 'next/link';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useKeyPress } from '@/hooks/useKeyPress';
import { useDebouncedCallback } from 'use-debounce';
import getConfig from 'next/config';
import TickerLogo from '../Elements/TickerLogo';
import { useTheme } from 'next-themes';
import { topicScale } from '../utils/colors';
import { useUser } from '@/utils/useUser';
import dayjs from 'dayjs';
import { MessageCircle, Search as SearchIcon } from 'lucide-react';
import { fixLink } from '../Elements/TickerLink';
import { useNotification } from '@/hooks/useNotification';
import { supabase } from '@/utils/supabase-client';
const marketFilterOptions = [
  { value: 'All', label: 'All' },
  { value: 'STOCKS', label: 'Stocks' },
  { value: 'PRIVATE', label: 'Private' },
  { value: 'ECONOMIC_INDICATOR', label: 'Economic Indicator' },
  { value: 'COMMODITY', label: 'Commodity' },
  { value: 'CRYPTO', label: 'Crypto' },
  { value: 'OTC', label: 'Otc' },
  { value: 'FX', label: 'Fx' }
];

const isUrl = (input: string) => {
  const urlRegex =
    /^(https?:\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/i;
  return urlRegex.test(input);
};

const tabs = [
  { label: 'Symbols', path: 'symbols' },
  { label: 'Stories', path: 'clusters' }
];
const { publicRuntimeConfig } = getConfig();

type SearchProps = {
  navbarMode?: boolean;
  loadFocussed?: boolean;
};

const Search = ({ navbarMode = true, loadFocussed = false }: SearchProps) => {
  const router = useRouter();
  const [activeIndex, setActiveIndex] = useState(-1);
  const [resultCount, setResultCount] = useState(0);
  const [searchActive, setSearchActive] = useState(false);
  const [input, setInput] = useState('');
  const [result, setResult] = useState<any>([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const [onboardMode, setOnboardMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const notification = useNotification();

  const [marketFilters, setMarketFilters] = useState(
    marketFilterOptions[0].value
  );

  const colors = useMemo(() => {
    return topicScale();
  }, []);
  const resetSearch = () => {
    disableSearch();
    setMarketFilters(marketFilterOptions[0].value);
    setActiveIndex(-1);
    setResult([]);
    setActiveTab(0);
    inputRef.current?.blur();
  };

  useEffect(() => {
    const resclos = () => {
      resetSearch();
    };

    router.events.on('routeChangeStart', resclos);

    return () => {
      router.events.off('routeChangeStart', resclos);
    };
  }, []);

  const link = router.query.ticker ? router.pathname : '/symbols/[ticker]';
  const indexIncrement = useRef(0);
  const [activeTab, setActiveTab] = useState(0);

  const doSearch = useDebouncedCallback((queryString: string) => {
    const currentIdx = indexIncrement.current;
    const url = `${
      publicRuntimeConfig.baseUrl
    }/api/v2/search?q=${queryString}&limit=35${
      marketFilters != 'All' ? `&marketFilters=${marketFilters}` : ''
    }`;
    setActiveIndex(-1);
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setResult(data);
      })
      .catch((e) => {
        console.error(e);
      });
  }, 100);

  const { user, chatApi } = useUser();
  const { doSubmit: submitChatQuestion } = chatApi;
  const disableSearch = () => {
    setSearchActive(false);
    setMarketFilters(marketFilterOptions[0].value);
    setInput('');
  };
  const updateSearchInput = (newInput: string) => {
    indexIncrement.current = indexIncrement.current + 1;
    if (newInput.length > 0) {
      setSearchActive(true);
    }
    setInput(newInput);

    // Use it like this:
    if (user?.id && isUrl(newInput)) {
      console.log('Input is a URL');
      setOnboardMode(true);
    } else {
      setOnboardMode(false);
      console.log('Input is not a URL');
    }
  };

  const nextItem = () => {
    setActiveIndex((activeIndex + 1) % resultCount);
  };
  const prevItem = () => {
    setActiveIndex((activeIndex - 1 + resultCount) % resultCount);
  };

  const doOnboard = async () => {
    setLoading(true);
    const { data, error } = await supabase
      .from('msh_company_requests')
      .insert({ query: input, compile_data: true });

    // const result = await fetch(
    //   `/api/research/company/profile?domain=${newReport}&compile=${
    //     compile ? 'true' : 'false'
    //   }`
    // ).then((res) => res.json());

    if (error) {
      setLoading(false);
      notification.showError({
        title: 'Error',
        message: `Could not create report. Please try again later`
      });
    } else {
      setLoading(false);
      notification.showNotification({
        title: 'Success',
        message: `Report Was successfully created.`
      });
      setSearchActive(false);
      setInput('');
      // setNewReport('');
    }
    console.log('Onboard');
  };

  useEffect(() => {
    doSearch(input);
  }, [input, marketFilters]);

  const focus = () => {
    inputRef.current?.focus();
  };

  useEffect(() => {
    loadFocussed && focus();
  }, []);

  const { resolvedTheme: theme } = useTheme();

  const go = () => {
    if (!searchActive) {
      return;
    }
    if (activeIndex >= 0 && activeIndex < result.length) {
      router.push(fixLink(router, link, result[activeIndex]?.msh_id));
    }
  };
  const handleEscape = () => {
    resetSearch();
    if (input.length < 1) {
      disableSearch();
    }
  };

  const ctrl = useKeyPress('k', focus, true, true);
  useKeyPress('ArrowDown', nextItem, false);
  useKeyPress('ArrowUp', prevItem, false);
  useKeyPress('Enter', go, false);
  useKeyPress('Escape', handleEscape, false);
  const inputChattable = user?.id && input?.length > 8 && input?.includes(' ');

  return (
    <Fragment>
      {searchActive && (
        <div
          className={cn(' h-screen  top-0 left-0 z-50 transition-all	', {
            'bg-black bg-opacity-50 fixed w-screen': navbarMode,
            hidden: !navbarMode
          })}
          onClick={() => disableSearch()}
        ></div>
      )}
      <div
        className={cn(
          'relative  items-center text-gray-700  border-line h- z-50   lg:top-1.5',
          {
            '  md:w-1/3  mx-auto md:absolute md:left-1/2 lg:-translate-x-1/2 lg:-ml-10 -ml-5 ':
              navbarMode,
            'w-full mb-5 ': !navbarMode
          }
        )}
      >
        {/* {ctrl && (
          <span className="flex h-5 w-5 absolute left-0 top-2/3">
            <span className="animate-ping absolute inline-flex h-full w-full rounded bg-amber-400 opacity-30"></span>
            <span className="relative inline-flex rounded h-5 w-5 bg-amber-500  items-center justify-center text-white text-xs font-bold">
              K
            </span>
          </span>
        )} */}

        <input
          className={cn('input-default px-5 pr:0  w-full text-md ', {
            'shadow-lg': searchActive && navbarMode
          })}
          value={input}
          ref={inputRef}
          type="search"
          autoComplete="off"
          onFocus={() => setSearchActive(true)}
          name="search"
          placeholder="Search"
          onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
            updateSearchInput(e.target.value)
          }
        />
        <button
          type="submit"
          name="submit search"
          className={cn('absolute top-1/2 right-3 -translate-y-1/2 ', {
            'opacity-0': searchActive
          })}
        >
          <SearchIcon className="text-gray-400 w-4 h-4" />
        </button>
      </div>
      {searchActive && (
        <div className="relative z-50 h-full flex  ">
          <div
            className={cn('bg-white dark:bg-black/95    z-50  py-5 ', {
              'max-w-3xl  fixed w-screen max-h-screen h-screen-1/2 md:max-h-[80vh]  dark:backdrop-blur-xl overflow-y-auto lg:rounded-lg md:w-1/2  inset-center-x top-20':
                navbarMode,
              'border border-line': navbarMode,
              'w-full': !navbarMode
            })}
          >
            <div className="w-full border-b border-line pb-2">
              <div className="flex flex-row justify-between items-center px-5 w-full">
                <div className="flex flex-row items-center gap-x-3 ">
                  {tabs.map((t, ti) => (
                    <button
                      key={`s-ss-${t.path}`}
                      onClick={() => setActiveTab(ti)}
                      className={cn('text-xs py-2 px-3 rounded-lg', {
                        'bg-blue-600 text-white': ti == activeTab
                      })}
                    >
                      {t.label} ({result[t.path]?.hits?.total?.value || 0})
                    </button>
                  ))}
                </div>
              </div>
            </div>
            {activeTab == 0 && (
              <div>
                <div className="pt-3 pb-5 px-5 flex-wrap flex flex-row gap-x-2 gap-y-2">
                  {marketFilterOptions?.map((c, i) => (
                    <button
                      key={c.value}
                      className={cn(
                        `capitalize px-3 py-2 text-xs whitespace-nowrap font-medium rounded-lg select-none opacity-100 transition border border-line hover:bg-blue-500 hover:text-white`,
                        {
                          'bg-blue-700 text-white': marketFilters == c.value
                        }
                      )}
                      onClick={() => {
                        if (c.value == 'All') {
                          setMarketFilters(marketFilterOptions[0].value);
                        } else {
                          setMarketFilters(c.value);
                        }
                      }}
                    >
                      {c.label}
                    </button>
                  ))}
                </div>
                <div>
                  {onboardMode && (
                    <div className="w-full hover:bg-blue-500 hover:text-white py-4 text-center">
                      <button
                        onMouseEnter={() => {
                          setActiveIndex(-1);
                        }}
                        onClick={() => {
                          doOnboard();
                        }}
                      >
                        <h2 className="font-bold">Onboard Company</h2>
                        <h3>
                          Create report for {input}. We will inform you once it
                          is ready
                        </h3>
                      </button>
                    </div>
                  )}
                  {result?.symbols?.hits?.hits.length > 0 ? (
                    <ul className={`mb-5`}>
                      {result?.symbols?.hits?.hits
                        .map((h) => h?._source)
                        .map((quote: RealtimeQuote, idx: number) => {
                          return (
                            <Link
                              key={`idx-${quote.msh_id}-${idx}`}
                              passHref
                              legacyBehavior
                              href={fixLink(router, link, quote.msh_id)}
                            >
                              <a className="max-w-md">
                                <li
                                  className={`px-2 ${
                                    // TODO: index accross the asset types.
                                    activeIndex == idx
                                      ? 'bg-blue-500 hover:bg-blue-600 text-white'
                                      : 'hover:bg-blue-500 '
                                  }`}
                                  onMouseDown={(e) => {
                                    e.preventDefault();
                                  }}
                                  onMouseEnter={() => {
                                    setActiveIndex(idx);
                                  }}
                                >
                                  <div className="py-2 px-2 cursor-pointer flex flex-row ">
                                    <div className="w-10 h-10 rounded mr-3 flex-shrink-0 ">
                                      <TickerLogo quote={quote} />
                                    </div>
                                    <div className={'leading-tighter'}>
                                      <h3 className="text-xs whitespace-nowrap flex flex-col font-medium ">
                                        {quote.msh_id}
                                      </h3>
                                      <span className=" font-medium">
                                        {quote.name}
                                      </span>
                                    </div>
                                  </div>
                                </li>
                              </a>
                            </Link>
                          );
                        })}
                    </ul>
                  ) : (
                    <div className="">
                      <p className="text-center opacity-80 text-sm m-5 py-10 border-line border-y">
                        No Results Found for Your Query
                      </p>
                    </div>
                  )}
                </div>
              </div>
            )}
            {activeTab == 1 && (
              <div className="flex flex-col gap-y-5 py-5">
                {result?.clusters?.hits?.hits.length > 0 ? (
                  result?.clusters?.hits?.hits
                    .map((h) => h?._source)
                    .map((hit, hi) => (
                      <Link
                        key={`hi-${hi}`}
                        className="px-5"
                        href={`/stories/${hit.slug}`}
                      >
                        <div className="text-sm opacity-60">
                          {hit.topic_class} -{' '}
                          {dayjs(hit.last_appearance).format('DD-MM-YYYY ')}
                        </div>
                        <h2 className="font-bold">
                          {hit.description} ({hit.news_count})
                        </h2>
                        <p className="text-sm">{hit.summary.slice(0, 140)}..</p>
                      </Link>
                    ))
                ) : (
                  <div className="">
                    <p className="text-center opacity-80 text-sm m-5 py-10 border-line border-y">
                      No Results Found for Your Query
                    </p>
                  </div>
                )}
              </div>
            )}
            <div className=" px-5 py-2 text-center text-blue-600 hover:text-blue-500 text-sm flex flex-row justify-center">
              <button
                onClick={() => {
                  resetSearch();
                  submitChatQuestion(input);
                }}
                className={cn('flex flex-row items-center gap-x-1 transition', {
                  'opacity-0': !inputChattable,
                  'opacity-100': inputChattable
                })}
              >
                <span className="text-xs">
                  <MessageCircle />
                </span>
                Ask chat question: {input}
              </button>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Search;
