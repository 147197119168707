import React, { useRef } from 'react';

export const useKeyPress = (
  targetKey: string,
  callback: () => void,
  useCtrl = false,
  preventDefault = false
) => {
  const [keyPressed, setKeyPressed] = React.useState(false);
  const [ctrlPressed, setCtrlPressed] = React.useState(false);
  const ctrlTimeout = useRef<ReturnType<typeof setTimeout>>();
  const ctrlTimeoutClr = useRef<ReturnType<typeof setTimeout>>();

  React.useEffect(() => {
    const downHandler = (e: KeyboardEvent) => {
      if (e.ctrlKey) {
        if (ctrlTimeout.current) {
          clearTimeout(ctrlTimeout.current);
        }
        if (ctrlTimeoutClr.current) {
          clearTimeout(ctrlTimeoutClr.current);
        }
        ctrlTimeout.current = setTimeout(() => {
          setCtrlPressed(true);
          ctrlTimeoutClr.current = setTimeout(() => {
            setCtrlPressed(false);
          }, 4000);
        }, 500);
      }
      if (e.key === targetKey && e.ctrlKey === useCtrl) {
        setKeyPressed(true);
        callback && callback();
        if (preventDefault) {
          e.preventDefault();
        }
      }
    };

    const upHandler = (e: KeyboardEvent) => {
      setTimeout(() => {
        if (ctrlTimeout.current) {
          clearTimeout(ctrlTimeout.current);
        }
        setCtrlPressed(false);
      }, 100);
      if (e.key === targetKey) setKeyPressed(false);
    };

    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, [callback, targetKey, useCtrl]);

  return ctrlPressed;
};
